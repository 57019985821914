<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <div class="wrap-content">
                <div class="title-page">Balance Management</div>
                <div class="filter">
                    <div>
                        <div class="wrap-input">
                            <label class="label-input long-label">Total Balance Under Management</label>
                            <span>IDR</span>
                        </div>
                        <div class="wrap-input">
                            <label class="label-input long-label">BUMN</label>
                            <span>USD</span>
                        </div>
                    </div>
                    <div>
                        <div class="wrap-input">
                            <label class="label-input">Periode</label>
                            <select>
                                <option selected value="all">Year</option>
                                <option value="lp1">Monthly</option>
                                <option value="lp2">Weekly</option>
                                <option value="lp3">Daily</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <table>
                        <thead>
                            <tr id="top">
                                <th scope="col" rowspan="3" style="width: 70px">No</th>
                                <th scope="col" rowspan="3" style="width: 120px">Intermediary Broker</th>
                                <th scope="col" rowspan="3">IB Code</th>
                                <th scope="col" colspan="2" rowspan="2">Current BUM</th>
                                <th scope="col" rowspan="3">No. of AUM</th>
                                <th scope="col" colspan="4">Balance Turn Over</th>
                            </tr>
                            <tr>
                                <th scope="col" colspan="2">Deposit</th>
                                <th scope="col" colspan="2">Year</th>
                            </tr>
                            <tr>
                                <th scope="col">IDR</th>
                                <th scope="col">$US</th>
                                <th scope="col">IDR</th>
                                <th scope="col">$US</th>
                                <th scope="col">IDR</th>
                                <th scope="col">$US</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>IB1</td>
                                <td>A</td>
                                <td>B</td>
                                <td>C</td>
                                <td>D</td>
                                <td>E</td>
                                <td>F</td>
                                <td>G</td>
                                <td>H</td>
                                <td>I</td>
                            </tr>
                            <tr>
                                <td>IB2</td>
                                <td>A</td>
                                <td>B</td>
                                <td>C</td>
                                <td>D</td>
                                <td>E</td>
                                <td>F</td>
                                <td>G</td>
                                <td>H</td>
                                <td>I</td>
                            </tr>
                            <tr>
                                <td>IB3</td>
                                <td>A</td>
                                <td>B</td>
                                <td>C</td>
                                <td>D</td>
                                <td>E</td>
                                <td>F</td>
                                <td>G</td>
                                <td>H</td>
                                <td>I</td>
                            </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="lengthData"
                        :per-page="perPage"
                        @change="handlePageChange"
                        aria-controls="new-table"
                        first-number
                        last-number
                        v-show="lengthData > 10"
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            dataTransaction: [],
            pageOfItems: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            offset: 0,
            data: [],
            form: {
                startDate: "",
                endDate: "",
            },
        };
    },
    mounted() {},
    methods: {
        // handlePageChange(value) {
        //   // this.getData(value);
        //   window.scrollTo({ top: 0, behavior: "smooth" });
        // },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.main-content {
    margin-top: 2rem;
}

/* .wrap-content {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 12px;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 115px;
}
select {
    width: 150px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.long-label {
    width: 220px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}
</style>
